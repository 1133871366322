import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/joy';
import { Pause, PlayArrow } from '@mui/icons-material';

const SpeechPlayer = ({ speech, completed, cancelled }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const readyToPlay = () => {
      console.log('ready to play..');
      speech.play();
    };
    speech.addEventListener('canplaythrough', readyToPlay);

    const audioEnded = () => {
      console.log('audio finished playing..');
      completed();
    };
    speech.addEventListener('ended', audioEnded);

    const audioPaused = () => {
      console.log('audio paused..');
      setIsPlaying(false);
    };
    speech.addEventListener('pause', audioPaused);

    const audioPlaying = () => {
      console.log('audio playing..');
      setIsPlaying(true);
    };
    speech.addEventListener('playing', audioPlaying);

    return () => {
      console.log('unmounting speech player');
      speech.pause();
      speech.removeEventListener('canplaythrough', readyToPlay);
      speech.removeEventListener('ended', audioEnded);
      speech.removeEventListener('pause', audioPaused);
      speech.removeEventListener('playing', audioPlaying);
    };
  }, [completed, speech]);

  return (
    <Box justifyContent="space-evenly" display="flex" width="100%" padding={3}>
      <Button color="danger" variant="outlined" onClick={() => cancelled()}>
        Cancel
      </Button>
      <Button
        variant="outlined"
        startDecorator={isPlaying ? <Pause /> : <PlayArrow />}
        onClick={() => (isPlaying ? speech.pause() : speech.play())}
      >
        {isPlaying ? 'Pause' : 'Resume'}
      </Button>
    </Box>
  );
};

export default SpeechPlayer;
